<template>
  <va-card
    class="map-widget"
    :title="$t('dashboard.mti_map.label')"
  >
    <va-inner-loading :loading="loading">
      <div class="row">
        <div
          class="flex xs8"
          v-if="mti.length > 0"
        >
          <map-chart :remote-data="mti" />
        </div>
        <div class="flex xs4">
          <h6>{{ $t('dashboard.mti_map.period', { period: currentPeriod }) }}</h6>
          <h6>Top 10</h6>
          <ul>
            <li
              v-for="church of topMTI"
              :key="church.name"
            >{{ church.name }} <span>{{ church.value }}</span></li>
            <li v-if="maxMTI > -1">
              <strong>{{ $t('layout.rest_countries_less_than', { value: maxMTI }) }}</strong>
            </li>
          </ul>
        </div>
      </div>
    </va-inner-loading>
  </va-card>
</template>

<script>
import { mapGetters } from 'vuex'
const MapChart = () => import(/* webpackPrefetch: true */ '@/components/statistics/charts/MapChart')

export default {
  name: 'dashboard-map-chart',
  components: { MapChart },
  data () {
    return {
      loading: false,
      error: false,
      currentPeriod: '',
      mti: [],
      topMTI: [],
      maxMTI: -1,
    }
  },
  computed: {
    ...mapGetters(['currentLocale', 'dashboardPeriod']),
  },
  watch: {
    dashboardPeriod (val) {
      if (val != null) {
        this.getMtiData(val)
        this.currentPeriod = this.getPeriod(val, 10)
      }
    },
  },
  created () {
    this.getMtiData()
    this.currentPeriod = this.getPeriod()
  },
  methods: {
    getChurchName (church) {
      let translate = ''
      if (this.currentLocale === 'esp' && church.name) {
        translate = church.name
      } else if (this.currentLocale === 'por' && church.name_pt) {
        translate = church.name_pt
      } else {
        translate = church.name_en
      }

      return translate
    },
    getPeriod (year, month) {
      const date = new Date()
      let yearRef = Number(year)
      if (!year) {
        yearRef = date.getFullYear()
        month = date.getMonth()
      }
      let yearBefore = yearRef

      if (month < 9) {
        yearBefore -= 1
      } else {
        yearRef += 1
      }

      return yearBefore + '-' + yearRef
    },
    async getMtiData (year) {
      this.loading = true
      this.error = false
      let d = null

      let query = ''
      if (year) {
        query += `?year=${year}`
      }
      try {
        d = await this.$http.get(`mti/numbers${query}`)
      } catch (e) {
        this.error = true
        return
      }

      let mti = d.data.data || []
      mti = mti.sort((a, b) => b.value - a.value)
      for (const m of mti) {
        m.name = this.$t(m.name)
      }
      this.mti = mti
      const firsts = this.mti.slice(0, 10)
      this.topMTI = firsts
      if (this.mti.length >= 10) {
        const lasts = this.mti.slice(10).map(x => x.value)
        this.maxMTI = Math.max(...lasts)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.map-widget {
  li {
    span {
      float: right;
    }
  }
}
</style>
